import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import PasswordDialog from "@/components/PasswordDialog";
import { TokenStatusEnum } from "@/enums";
import assetModel from "@/models/asset.model";
import globalModel from "@/models/global.model";
import settingModel from "@/models/setting.model";
import { hideString } from "@/utils/string";
import { useReactive, useRequest } from "ahooks";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import {
  Button,
  Cell,
  CellGroup,
  Dialog,
  Field,
  Form,
  Image,
  ImagePreview,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

export default () => {
  const state = useReactive({
    scrollTop: 0,
    visible: false,
  });
  const { user } = useSnapshot(globalModel.state);
  const [_, copy] = useCopyToClipboard();
  const nav = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const price = Form.useWatch("price", form);
  const { data: fee } = useRequest(() => {
    return settingModel.getItem("fee").then((res) => res.data);
  });
  const { data, refreshAsync } = useRequest(
    () => {
      return assetModel.getToken(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
    }
  );
  const isOwner = useMemo(() => user?.id === data?.ownerId, [user, data]);
  return (
    <div className={styles.page}>
      <NavBar
        title={state.scrollTop > 100 ? "资产详情" : ""}
        theme="none"
        placeholder={false}
        onScroll={(e) => (state.scrollTop = e)}
      />
      {!data ? (
        <Loader />
      ) : (
        <>
          <Image
            className={styles.image}
            src={data.asset?.image}
            fit="cover"
            onClick={() =>
              ImagePreview.open({
                showIndex: false,
                lazyload: true,
                images: [data.asset?.image],
              })
            }
          />

          <div className={styles.asset}>
            <div className={styles.title}>
              {data.asset?.title}#{data?.no}
            </div>
            <div className={styles.tags}>
              <div className={styles.limit}>
                <span className={styles.label}>限量</span>
                <span className={styles.num}>{data.asset?.limit}份</span>
              </div>
              <div className={styles.limit}>
                <span className={styles.label}>流通</span>
                <span className={styles.num}>{data.asset?.circulation}份</span>
              </div>
            </div>
          </div>

          <div className={styles.publisher}>
            <div className={styles.left}>
              <Image
                className={styles.logo}
                src={data.asset?.publisher?.logo}
              />
              <div className={styles.info}>
                <div className={styles.label}>发行方</div>
                <div className={styles.name}>{data.asset?.publisher?.name}</div>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.logo} src={data.asset?.creator?.logo} />
              <div className={styles.info}>
                <div className={styles.label}>创作者</div>
                <div className={styles.name}>{data.asset?.creator?.name}</div>
              </div>
            </div>
          </div>
          <div className={styles.group}>
            <CellGroup>
              <Cell title="所属专辑" value={data.asset?.album?.title} />
              <Cell
                title="链上哈希"
                value={
                  <div className={styles.hash}>
                    {hideString(data.hash, 6, 4)}
                  </div>
                }
                onClick={() => {
                  copy(data.hash);
                  Toast({
                    type: "success",
                    message: "复制成功",
                  });
                }}
              />
              <Cell title="资产编号" value={`#${data.no}`} />
            </CellGroup>
          </div>
          <div className={styles.section}>
            <span>设置价格</span>
            <span className={styles.extra}>
              您的买入价: ¥ {data?.bidPrice ?? "--"}
            </span>
          </div>
          <div className={styles.group}>
            <Form className={styles.form} layout="vertical" form={form}>
              <Form.Item
                name="price"
                rules={[
                  {
                    validator: async (_, value) => {
                      try {
                        if (!value) {
                          throw new Error("请输入寄售价格");
                        }
                        if (value < data.asset?.lowestPrice) {
                          throw new Error(
                            `寄售价格不能小于${data.asset?.lowestPrice}元`
                          );
                        }
                        if (value > data.asset?.highestPrice) {
                          throw new Error(
                            `寄售价格不能大于${data.asset?.highestPrice}元`
                          );
                        }
                      } catch (err: any) {
                        return Promise.reject(err);
                      }
                    },
                  },
                ]}
                noStyle
              >
                <Field
                  className={styles.field}
                  prefix="¥"
                  placeholder="请输入价格"
                  type="digit"
                  clearable
                />
              </Form.Item>
            </Form>
          </div>
          <div className={styles.section}>
            <span>预计收入</span>
          </div>
          <div className={styles.group}>
            <CellGroup>
              <Cell
                title="总售价"
                value={`¥ ${price ? new Decimal(price).toFixed(2) : "--"}`}
              />
              <Cell
                title={`综合服务费(${(fee ?? 0) * 100}%)`}
                value={`¥ ${
                  price ? new Decimal(price * (fee ?? 0)).toFixed(2) : "--"
                }`}
              />
              <Cell
                title="预计收入"
                value={`¥ ${
                  price
                    ? new Decimal(price - price * (fee ?? 0)).toFixed(2)
                    : "--"
                }`}
              />
            </CellGroup>
          </div>

          <div className={styles.section}>寄售须知</div>
          <div className={styles.tips}>
            <p>
              1、寄售收入在扣除综合服务费（{(fee ?? 0) * 100}
              %）后，将自动转入钱包余额。
            </p>
            <p>2、寄售收入的增值部分，请您主动申报个人所得税。</p>
          </div>
          <div className={styles.footer}>
            {data.status === TokenStatusEnum.LOCKING && (
              <Button className={styles.cancel} type="primary" block disabled>
                支付锁定中...
              </Button>
            )}
            {data.status === TokenStatusEnum.HOLD && isOwner && (
              <>
                <Button
                  className={styles.submit}
                  type="primary"
                  block
                  disabled={!data.asset.isTradable || !price || price <= 0}
                  onClick={async () => {
                    try {
                      await form.validateFields();
                      state.visible = true;
                    } catch (e: any) {
                      if (e && e.errorFields) {
                        Toast({
                          message: e.errorFields[0]?.errors[0],
                          position: "top",
                        });
                      }
                    }
                  }}
                >
                  {data.asset.isTradable ? "立即寄售" : "暂未开放寄售"}
                </Button>
              </>
            )}
            {data.status === TokenStatusEnum.ON_SALE ? (
              isOwner ? (
                <>
                  <Button className={styles.submit}
                    type="primary"
                    block
                    onClick={async () => {
                      await Dialog.confirm({
                        title: "确定要取消寄售吗?",
                      });
                      const update = Toast({
                        type: "loading",
                        message: "正在提交...",
                        forbidClick: true,
                        duration: 0,
                      });
                      const res = await assetModel.offSale(Number(params.id));
                      if (res?.code === 200) {
                        await refreshAsync();
                        update.config({
                          type: "success",
                          message: "取消成功",
                          forbidClick: true,
                        });
                      }
                      setTimeout(() => {
                        update.clear();
                      }, 2000);
                    }}
                  >
                    取消寄售
                  </Button>
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {data.status === TokenStatusEnum.DESTROYED && (
              <Button className={styles.cancel} type="primary" block disabled>
                已销毁
              </Button>
            )}
          </div>
          <PasswordDialog
            visible={state.visible}
            onClose={() => (state.visible = false)}
            onConfirm={async (pass) => {
              state.visible = false;
              const update = Toast({
                type: "loading",
                message: "正在提交...",
                forbidClick: true,
                duration: 0,
              });
              const res = await assetModel.onSale({
                id: params.id,
                price,
                pass,
              });
              if (res?.code === 200) {
                await refreshAsync();
                update.config({
                  type: "success",
                  message: "上架成功",
                  forbidClick: true,
                });
                nav(-1);
              }
              setTimeout(() => {
                update.clear();
              }, 2000);
            }}
          />
        </>
      )}
    </div>
  );
};
