import { useReactive } from "ahooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, PasswordInput, Space } from "react-vant";
import styles from "./index.module.css";

interface PasswordDialogProps {
  visible?: boolean;
  message?: React.ReactNode;
  onClose?: () => void;
  onConfirm?: (v: string) => Promise<void | boolean>;
}

export default ({
  visible,
  message,
  onClose,
  onConfirm,
}: PasswordDialogProps) => {
  const nav = useNavigate();
  const state = useReactive({
    value: "",
  });
  useEffect(() => {
    if (!visible) {
      state.value = "";
    }
  }, [visible]);

  return (
    <Dialog
      className={styles.container}
      title={
        <Space direction="vertical" gap={0}>
          <Space>请输入操作密码</Space>
          <a
            className={styles.link}
            onClick={() => {
              onClose && onClose();
              nav("/setting/security");
            }}
          >
            设置操作密码
          </a>
        </Space>
      }
      visible={visible}
      closeable
      showConfirmButton={false}
      onClose={onClose}
      onCancel={onClose}
    >
      <PasswordInput
        className={styles.input}
        gutter={10}
        onSubmit={onConfirm}
        value={state.value}
        onChange={(e) => (state.value = e)}
      />
      <div className={styles.tips}>{message}</div>
    </Dialog>
  );
};
